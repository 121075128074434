<template>
  <div class="desktop_area_on">

    <div class="directflightsonly_top d-flex align-items-center" v-if="isOdysseySite">
      <div class="form-row" :class="{ 'col-md-12 mt-1': type!=='tabs' }">
        <b-form-group id="input-group-2" class="agent-select" label-for="input-2" :class="type==='tabs' ? 'col-md-3': 'col-md-12'">
          <v-select
            v-model="agent"
            :reduce="agent => agent.value"
            :options="agentList"
            dir="rtl"
            :clearable="false"
            :searchable="true"
            :disabled="loginOdyAgentCode!==changeEnableAgentCode"
          >
          </v-select>
        </b-form-group>
      </div>
    </div>

    <div class="directflightsonly_top d-flex align-items-center" v-if="isVacation || isORG">
      <sub-category-dropdown :options="subCategoriesOption" :category="currCategory"/>
    </div>

    <div class="directflightsonly_top directbottom align-items-center" :class="{'d-flex': device==='desktop'}" v-if="isVacation || isORG">
      <div class="searchtabgrouplist" v-if="isORG" :class="{'d-flex': device==='desktop'}">
        <search-destination @closed="closed" :category="currCategory" componentName="destination"/>
        <search-destination @closed="closed" :category="currCategory" componentName="months"/>
        <passenger-selection @closed="closed" componentName="composition"/>
      </div>
      <div class="searchtabgrouplist" v-else :class="{'d-flex': device==='desktop'}">
        <search-destination @closed="closed" :category="currCategory" componentName="destination"/>
        <date-range-dropdown @closed="closed" componentName="calendar"/>
        <passenger-selection @closed="closed" componentName="composition"/>
      </div>

      <button class="btn btn-outline-primary" :class="{'disabled': (!isSearchAvailable || isLoading)}" @click="checkPossibleSearch">
        {{$t("sabre.buttons.update-search")}}
      </button>

    </div>
    <search-panel v-if="device==='desktop' && isFO" location="home"/>
    <search-mobile-panel v-if="device==='mobile' && isFO" location="home"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import {
  BFormGroup,
} from 'bootstrap-vue';
import TemplateSearchTabFH from '../searchTabFH/TemplateSearchTabFH';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'sabre-search-fh-pane',
  components: {
    vSelect,
    BFormGroup,
    searchDestination: () => import('./FHDestinationDropdown'),
    dateRangeDropdown: () => import('./DateRangeDropdown'),
    passengerSelection: () => import('./FHCompositionDropdown'),
    subCategoryDropdown: () => import('./FHSubCateDropdown'),
    SearchPanel: () => import('@/sabre/searchPanel/desktop/searchDesktopPanel'),
    SearchMobilePanel: () => import('@/sabre/searchPanel/mobile/searchMobilePanel'),
  },
  extends: TemplateSearchTabFH,
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      chosenDestinationCodes: 'GET_FH_CHOSEN_DESTINATIONS',
      storedFromDate: 'GET_FH_FROM_DATE',
      storedToDate: 'GET_FH_TO_DATE',
      adult: 'GET_FH_ADULTS',
      child: 'GET_FH_CHILDREN',
      infant: 'GET_FH_INFANTS',
      senior: 'GET_FH_PENSIONERS',
      storedMonths: 'GET_FH_YEAR_MONTHS',
      subCategories: 'GET_FH_SUB_CATEGORY_LIST',
      selectedSubCategory: 'GET_FH_SELECTED_SUB_CATE',
    }),
    isSearchAvailable() {
      const { chosenDestinationCodes, storedFromDate, storedToDate, adult, isORG, storedMonths } = this;
      return isORG ? chosenDestinationCodes && chosenDestinationCodes[0] && adult && storedMonths && storedMonths[0]
        : chosenDestinationCodes && chosenDestinationCodes[0] && storedFromDate && storedToDate && adult;
    },
    isVacation() {
      return this.category.dealTypeCode === 'vacation_pack';
    },
    isORG() {
      return this.category.dealTypeCode === 'Organize_tour_packages';
    },
    isFO() {
      return this.category.dealTypeCode === 'Flight_Only';
    },
    subCategoriesOption() {
      const { subCategories, lang, category } = this;
      if (!subCategories) return [];

      const list = subCategories.filter((sub) => (sub.packCategoryCode === category.dealTypeCode));
      return list.map((item) => ({
        id: item.id,
        value: item.id,
        label: item[`name${lang.charAt(0).toUpperCase() + lang.slice(1)}`],
        list: item.subjectCodes,
      }));
    },
  },
  mounted() {
    this.$store.dispatch('RESET_FH_SEARCH_CONDITION');
  },
  methods: {
    closed(type) {
      if (type === 'destination') {
        if (this.isORG) {
          this.$children.find((ch) => ch.componentName === 'months').openDropdown(); // new logic
        } else {
          this.$children.find((ch) => ch.componentName === 'calendar').openDropdown();
        }
      }
      // if (type === 'calendar' || type === 'months') {
      //   this.$children.find((ch) => ch.componentName === 'composition').openDropdown();
      // }
    },
    checkPossibleSearch() {
      if (!this.isSearchAvailable) return;

      const categoryId = this.currCategory.code;
      this.$store.dispatch('UPDATE_CURRENT_CATEGORY', { categoryId });

      const { chosenDestinationCodes, storedFromDate, storedToDate, adult, child, infant, senior, selectedSubCategory } = this;
      const { isORG, storedMonths } = this;

      const body = isORG ? {
        dest: chosenDestinationCodes.join(','),
        yearMonth: storedMonths.join(','),
        subjectIds: selectedSubCategory ? selectedSubCategory.join(',') : '',
        categoryId: this.currCategory.code,
        adult: adult + senior,
        child,
        infant,
      } : {
        dest: chosenDestinationCodes[0],
        fromDate: storedFromDate,
        toDate: storedToDate,
        subCategoryId: selectedSubCategory ? selectedSubCategory.join(',') : '',
        categoryId: this.currCategory.code,
        adult: adult + senior,
        child,
        infant,
        odyAgentCode: this.agent,
      };

      if (!selectedSubCategory || !selectedSubCategory.length) {
        delete body.subjectIds;
        delete body.subCategoryId;
      }

      if (this.$route.query.utm_source) body.utm_source = this.$route.query.utm_source;
      if (this.$route.query.secret) body.secret = this.$route.query.secret; // temporary param for Ran's search
      if (this.isLanding) body.utm_source = this.landingInfo.utm_source;
      if (this.marketerId) body.marketerId = this.marketerId;
      if (this.isFcAgentMarketerMode) body['fc-agent-mode'] = '';

      const { query } = this.$route;
      if (Object.keys(body).every((key) => String(body[key]) === String(query[key]))) return;

      if (this.isLoading) {
        this.$store.dispatch('FETCH_STOP');
        setTimeout(() => { this.startSearch(body); }, 100);
        return;
      }
      this.startSearch(body);
    },
    startSearch(body) {
      const queryString = `/search-result/?${new URLSearchParams(body).toString()}`;
      // this.$router.push(queryString, null, () => { this.$router.push(queryString); });
      this.$router.push(queryString);
      if (this.isLanding) {
        window.location.href = queryString;
      }
      return true;
    },
  },
};

</script>

<style scoped>
  .form-row {
    width: 100%;
  }
</style>

<style>
/**
  Agent selection part
*/

.vs__dropdown-toggle {
  background: #e9ecef !important;
}
.agent-select .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: 3rem;
  cursor: pointer;
}

.vs--disabled .vs__search, .vs--disabled .vs__open-indicator {
  background-color: inherit;
}
.vs--disabled .vs__open-indicator {
  fill: rgba(60,60,60,.2);
}
.vs--disabled.vs--single .vs__selected {
  color: #afafaf;
}
input.vs__search{
  color:#495057
}
</style>
